<template>
   <div class="container mx-auto sm:p-3 p-2 text-left bg-white shadow-md mt-10">
     <div class="header flex flex-row text-center mb-3">
        <img src="@/assets/images/email.svg" alt="" class="w-16">
        <!-- <h2 class="text-3xl font-bold ml-3">Success</h2> -->
     </div>
     <p>
         {{$t("The return procedure has just been forwarded to your e-mail address")}}: <strong>{{$route.query.email}}</strong>
     </p>
    <p>
      
      <!-- {{$t("Returning the product is quick and easy.")}} -->
    </p>
    <h3 class="text-2xl mt-3 font-bold">{{$t("STEP")}} 1</h3>
    <p>{{$t("Together with the product to be returned, enter your unique return code in the package.")}}</p>
    <p>{{$t("Your unique return number is")}}: <strong>{{$route.query.id}}</strong></p> 
    <p class="text-red-400">
      {{$t("ATTENTION: without the unique return code, your package cannot be identified.")}}
    </p>
    <h3 class="text-2xl mt-3 font-bold">{{$t("STEP")}} 2</h3>
    <p>{{$t("Send the package to the following address:")}}</p>
    <div class="flex flex-col bg-gray-50 border-gray-100 border-2 p-3 rounded-md " >
      <p class="font-sm" v-for="n in naslov" :key="n">
        {{n}}
      </p>
    </div>
  </div>
  
</template>

<script>
import naslovi from '@/assets/naslovi.json'
export default {
  computed: {
    naslov() {
      const {locale} = this.$i18n;
      const naslov = naslovi[locale];

      if (!naslov) {
        return naslovi['en'];
      }
      return naslov;
    }
  }

}
</script>

<style>

</style>